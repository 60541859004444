import React from 'react';
import client from "../assets/client.png";


export const WhatOurClients = ({ isOwner }) => {
  const tenantTestimonial = {
    quote: "Managing deposit and rent has never been this easy!",
    name: "Nikhil Ratta",
    role: "Tenant",
    image: client
  };

  const ownerTestimonial = {
    quote: "Finally, a platform that simplifies property management for landlords!",
    name: "Samantha Lee",
    role: "Property Owner",
    image: client
  };

  const testimonial = isOwner ? ownerTestimonial : tenantTestimonial;

  return (
    <div className="container mx-auto px-20 py-16 lg:py-20">
      <div className="flex flex-col lg:flex-row items-center justify-around max-w-6xl mx-auto space-y-8 lg:space-y-0 lg:space-x-8">
        {/* Testimonial Content */}
        <div className="w-full lg:w-1/2 text-center lg:text-left">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6 leading-tight">
            {isOwner ? "What Property Owners Say" : "What our clients say"} <br className="hidden md:block" /> about us
          </h2>
          
          <div className="relative mb-6">
            <blockquote className="text-xl md:text-2xl text-gray-800 italic font-medium relative z-10">
              "{testimonial.quote}"
            </blockquote>
          </div>
          
          <div className="mt-6">
            <p className="text-xl font-semibold text-gray-900">{testimonial.name}</p>
            <p className="text-base text-gray-600">{testimonial.role}</p>
          </div>
        </div>
        
        {/* Testimonial Image */}
        <div className="w-full lg:w-1/2 max-w-md">
          <div className="aspect-square overflow-hidden rounded-3xl shadow-lg transform transition-transform duration-300 hover:scale-105">
            <img
              src={testimonial.image}
              alt="Client testimonial"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatOurClients;