import React from "react";

const Button = ({ text }) => (
  <button className="w-full bg-blue-600 text-white py-3 px-6 rounded-md hover:bg-blue-700 transition-colors">
    {text}
  </button>
);

function GetInTouch() {
  return (
    <div id="contact" className="bg-[#07194a] w-full min-h-screen flex justify-center items-center px-4 py-12 md:py-16 lg:px-8">
      <div className="max-w-7xl w-full flex flex-col lg:flex-row justify-between items-center gap-8 lg:gap-16">
        {/* Text Content */}
        <div className="w-full lg:w-1/2 text-white space-y-6 lg:space-y-8">
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold leading-tight">
            Putting a plan to action,
            <br />
            to assure your satisfaction!
          </h1>
          <p className="text-base md:text-lg leading-relaxed max-w-xl">
          We ensure fair handling of security deposits, providing transparency and peace of mind for both owners and tenants.
          </p>
        </div>

        {/* Contact Form */}
        <div className="w-full lg:w-5/12 bg-[#05133a] rounded-xl p-6 md:p-8 lg:p-8 lg:pt-4">
          <div className="space-y-6">
            <div className="space-y-4">
              <h2 className="text-2xl md:text-3xl font-bold text-white">
                Get in touch
              </h2>
              {/* <p className="text-white text-base md:text-lg">
                Need more help?
                <br />
                contact us!
              </p> */}
            </div>

            <form className="space-y-4">
              <input
                type="text"
                placeholder="Your name"
                className="w-full h-12 px-4 rounded-md text-gray-600 placeholder-gray-500 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              
              <input
                type="email"
                placeholder="Your mail"
                className="w-full h-12 px-4 rounded-md text-gray-600 placeholder-gray-500 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              
              <input
                type="tel"
                placeholder="Your phone"
                className="w-full h-12 px-4 rounded-md text-gray-600 placeholder-gray-500 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              
              <textarea
                placeholder="Your message"
                rows={4}
                className="w-full p-4 rounded-md text-gray-600 placeholder-gray-500 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
              />
              
              <Button text="Send message" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};


export default GetInTouch;