import React from "react";
import { Wallet, Smile, Shield, Home, Key } from 'lucide-react';



// Reusable Card Component
const FeatureCard = ({ icon: Icon, title, description }) => {
  return (
    <div className="bg-[#07194a] text-white rounded-2xl p-6 md:p-8 text-center shadow-lg transform transition-all duration-300 hover:scale-105 hover:shadow-xl">
      <div className="flex justify-center mb-4 md:mb-6">
        <Icon className="w-12 h-12 md:w-16 md:h-16 text-white" />
      </div>
      <h3 className="text-xl md:text-2xl font-bold mb-3 md:mb-4">
        {title}
      </h3>
      <p className="text-sm md:text-base text-gray-200 line-clamp-2">
        {description}
      </p>
    </div>
  );
};


export const OurClientsSuccess = ({ isOwner }) => {
  const tenantFeatures = [
    {
      icon: Wallet,
      title: "Easy Rent Payments",
      description: "Pay your rent through our platform, always on time, with zero stress."
    },
    {
      icon: Smile,
      title: "Find Local Help & Services",
      description: "From cleaners to electricians, all the help you need is just a click away."
    },
    {
      icon: Shield,
      title: "No Disputes on Deposits",
      description: "We safeguard your deposit, so there's no back-and-forth with the landlord."
    }
  ];

  const ownerFeatures = [
    {
      icon: Home,
      title: "Property Management",
      description: "Easily manage multiple properties and track your rental income."
    },
    {
      icon: Key,
      title: "Tenant Screening",
      description: "Comprehensive background checks and tenant verification."
    },
    {
      icon: Shield,
      title: "Secure Payments",
      description: "Receive rent payments directly and securely through our platform."
    }
  ];

  const features = isOwner ? ownerFeatures : tenantFeatures;
  const headerText = isOwner 
    ? '"Simplify Your Property Management"' 
    : '"Your New Home, Without the Hassle"';
  const subHeaderText = isOwner
    ? "Manage properties efficiently, screen tenants, and secure your investments."
    : "Enjoy safe deposits, quick access to house help, and smooth rent payments – all in one place.";

  return (
    <div className="container mx-auto px-4 py-12">
      <div className="text-center mb-10 md:mb-12">
        <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
          {headerText}
        </h2>
        <p className="text-sm md:text-base text-gray-600 max-w-xl mx-auto">
          {subHeaderText}
        </p>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6 lg:gap-8">
        {features.map((feature, index) => (
          <FeatureCard
            key={index}
            icon={feature.icon}
            title={feature.title}
            description={feature.description}
          />
        ))}
      </div>
    </div>
  );
};


export default OurClientsSuccess;