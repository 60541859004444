import React from 'react';

// Toggle Button Component
export const ToggleButton = ({ isOwner, onToggle }) => {
  return (
    <div className="flex justify-center items-center mx-auto">
      <button
        className={`px-8 py-3 rounded-l-[20px] transition-all duration-300 ease-in-out ${
          !isOwner
            ? 'bg-[#07194a] text-white'
            : 'bg-gray-300 text-gray-700 hover:bg-gray-400'
        }`}
        onClick={onToggle}
      >
        Tenant
      </button>
      <button
        className={`px-8 py-3 rounded-r-[20px] transition-colors duration-300 ease-in-out ${
          isOwner
            ? 'bg-[#07194a] text-white'
            : 'bg-gray-300 text-gray-700 hover:bg-gray-400'
        }`}
        onClick={onToggle}
      >
        Owner
      </button>
    </div>
  );
};

export default ToggleButton;