import React from "react";
import howItWorks from "../assets/howItworks.png";


// const Button = ({ text, onClick }) => (
//   <button 
//     onClick={onClick} 
//     className="w-full md:w-auto px-6 py-3 bg-white text-blue-900 font-bold rounded-lg hover:bg-gray-100 transform transition-all duration-300 hover:scale-105 hover:shadow-xl"
//   >
//     {text}
//   </button>
// );

// Putting a Plan To Component
export const PuttingAPlanTo = ({ isOwner }) => {
  const tenantSteps = [
    {
      title: "1. Sign Up & Browse Properties",
      description: "Find homes that suit your needs."
    },
    {
      title: "2. Check Service Provider Listings",
      description: "Get contacts for trusted house help."
    },
    {
      title: "3. Enjoy Worry-Free Living",
      description: "Your deposit is protected, and your rent is always sorted."
    }
  ];

  const ownerSteps = [
    {
      title: "1. List Your Property",
      description: "Create a comprehensive listing with all property details."
    },
    {
      title: "2. Screen & Select Tenants",
      description: "Use our advanced screening tools to find the right tenants."
    },
    {
      title: "3. Manage Effortlessly",
      description: "Track rent, maintenance, and communicate seamlessly."
    }
  ];

  const steps = isOwner ? ownerSteps : tenantSteps;
  const headerText = isOwner ? "Property Management Made Simple" : "How It Works";
  const buttonText = isOwner ? "List Your Property Today →" : "Find Your Dream Home Today →";

  return (
    <div id="workflow" className="bg-[#07194a] rounded-3xl overflow-hidden py-12 md:py-20">
      <div className="container mx-auto pr-10 pl-10 md:pl-20 lg:pl-40">
        <div className="flex flex-col md:flex-row items-center justify-center gap-8 md:gap-16 lg:gap-24">
          {/* Image Container */}
          <div className="w-full md:w-1/2 lg:w-5/12 mb-8 md:mb-0">
            <div
              className="w-full aspect-square rounded-xl bg-cover bg-center"
              style={{ backgroundImage: `url(${howItWorks})` }}
            />
          </div>
          
          {/* Content Container */}
          <div className="w-full md:w-1/2 lg:w-7/12 text-white space-y-6">
            <h2 className="text-3xl md:text-4xl font-bold mb-6 text-center md:text-left">
              {headerText}
            </h2>
            
            <div className="space-y-4 mb-8 text-center md:text-left">
              {steps.map((step, index) => (
                <div key={index}>
                  <span className="font-bold block">{step.title}</span>
                  <p className="text-gray-200">{step.description}</p>
                </div>
              ))}
            </div>
            
            <button
              onClick={() => {/* Add navigation logic */}}
              className="w-full md:w-auto px-6 py-3 bg-white text-blue-900 font-bold rounded-lg hover:bg-gray-100 transform transition-all duration-300 hover:scale-105 hover:shadow-xl"
            >
              {buttonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PuttingAPlanTo;