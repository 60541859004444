// import logo from "./logo.svg";
import "./App.css";
import Header from "./components/Header";
import Carousel from "./components/Carousel";
import ToggleButton from "./components/ToggleButton";
import { OurClientsSuccess } from "./components/ClientSuccess";
import { PuttingAPlanTo } from "./components/HowItWorks";
import { WhatOurClients } from "./components/ClientReviews";
import GetInTouch from "./components/GetInTouch";
import Footer from "./components/Footer";
import { useState } from "react";

function App() {
  const [isOwner, setIsOwner] = useState(false);

  const toggleUserType = () => {
    setIsOwner((prev) => !prev);
  };

  return (
    <>
      <Header />
      <Carousel />
      {/* <ToggleButton />
      <OurClientsSuccess />
      <PuttingAPlanTo />
      <WhatOurClients /> */}

      <div>
        <ToggleButton isOwner={isOwner} onToggle={toggleUserType} />
        <OurClientsSuccess isOwner={isOwner} />
        <PuttingAPlanTo isOwner={isOwner} />
        <WhatOurClients isOwner={isOwner} />
      </div>

      <GetInTouch />
      <Footer />
    </>
  );
}

export default App;
