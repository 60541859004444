import React from "react";
import frame_img from "../assets/frame.png";

// Update the import path to the correct location of the Frame component
// import { Frame } from "../common/Frame"; // Ensure this path is correct
import "./carousel.css";

function Carousel() {
    return (
        <div id="home" className="flex flex-col items-center py-0 pb-14 w-full">
            <br></br><br></br><br></br><br></br>
        <div className="flex flex-col items-center w-full">
          <div 
            className="relative w-full md:w-11/12 lg:w-[1296px] h-[400px] md:h-[500px] lg:h-[600px] bg-black/80 rounded-none md:rounded-2xl lg:rounded-[44px]"
            style={{
              backgroundImage: `url(${frame_img})`,
              backgroundPosition: "center",
              backgroundSize: "cover"
            }}
          >
            <div className="absolute inset-0 flex flex-col items-center justify-center gap-6 md:gap-8 lg:gap-8 p-4 md:p-6 lg:p-8">
              <h1 className="text-3xl md:text-4xl lg:text-6xl font-bold text-white text-center leading-tight" style={{ fontFamily: '"Gantari-Bold", Helvetica' }}>
                "Hassle-Free Rentals for Tenants and<br />
                Property Owners"
              </h1>
              <p className="text-sm md:text-base lg:text-base text-white font-semibold text-center max-w-[90%] md:max-w-[80%] lg:max-w-[801px] line-clamp-2 font-['Inter']">
                Secure deposits, guaranteed rent, service providers on demand, and
                renovation coverage – simplifying rentals for everyone
              </p>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Carousel;